import { Button, Carousel, Col, Form, Modal, Radio, Row, Select, Space } from "antd";
import { useEffect, useRef, useState } from "react";
import { complexity, estimateLevel } from "../../data/quotulator";
import { DataMsgWrapper, FormContainer, SliderImg } from "./styles";
import { RightOutlined } from '@ant-design/icons';
import { ReactComponent as ConceptImg } from '../../images/quotulator/request_quote.svg'
import { ReactComponent as ConceptImg2 } from '../../images/quotulator/ads_click.svg'
import { ReactComponent as ConceptImg3 } from '../../images/quotulator/draw.svg'
import Icon from '@ant-design/icons';

export const InputLevel = (props) => {

  const [form] = Form.useForm();

  const carouselRef = useRef();

  const [estimateLevelValue, setEstimateLevelValue] = useState("");

  const [complexityObj, setComplexityObj] = useState(null);

  const [dataObj, setDataObj] = useState(null);

  const [showInsuffData, setShowInsuffData] = useState(false)

  useEffect(() => {
    if (props.savedValues) {
      var elementPos = props.complexityData.findIndex(obj => obj.id === parseInt(props.savedValues.complexity))
      carouselRef.current.goTo(elementPos)
      const getObj = props.complexityData.find(item => item.id === parseInt(props.savedValues.complexity))
      if (getObj) {
        setComplexityObj(getObj ? getObj : null);
      }

      if (parseInt(props.savedValues?.estimateData) < parseInt(props.savedValues?.level)) {
        setShowInsuffData(true)
      } else {
        setShowInsuffData(false)
      }
      // if(props.onCalculate) {
      //   let obj = {
      //     level: props.savedValues.level,
      //     complexity: parseInt(props.savedValues.complexity)
      //   };
      //   props.onCalculate(obj);
      // }
    }
  }, [props.savedValues])

  const onChange = (e) => {
    form.setFieldsValue({ level: e.target.value });
    setEstimateLevelValue(e.target.value)

    const getObj = props.estimateLevelData.find(item => item.estimate_level === e.target.value)

    const estimateDataObj = props.estData.find(item => item.value === form.getFieldValue("estimateData"))
    if (getObj && estimateDataObj && parseInt(getObj?.estimate_level) > parseInt(estimateDataObj?.value)) {
      setShowInsuffData(true)
    } else {
      setShowInsuffData(false)
    }

    if (getObj) {
      if (getObj.response) {
        Modal.info({
          title: getObj.response,
          content: "enquiries@productcostoptimisation.co.uk",
          onOk() { },
          className: "enquireModal",
          centered: true
        });
      }
    }

    // if(e.target.value === 3) {
    //   Modal.info({
    //     title: 'Please enquire directly',
    //     onOk() {},
    //     className: "enquireModal",
    //     centered: true
    //   });
    // }
  }

  const complexityChange = (value) => {
    form.setFieldsValue({ complexity: value });
    var elementPos = props.complexityData.findIndex(obj => obj.id === value)
    // var objectFound = array[elementPos];
    carouselRef.current.goTo(elementPos)
    const getObj = props.complexityData.find(item => item.id === value)
    if (getObj) {
      form.setFieldsValue({ assemblies: getObj.assemblies, sub_assemblies: getObj.sub_assemblies, parts: getObj.parts });
      setComplexityObj(getObj ? getObj : null);
      if (getObj.response) {
        Modal.info({
          title: getObj.response,
          content: "enquiries@productcostoptimisation.co.uk",
          onOk() { },
          className: "enquireModal",
          centered: true
        });
      }
    }
    // if(value === "complex_system") {
    //   form.setFieldsValue({ estimates: "", days: "", cost: "", assemblies: "", sub_assemblies: "", parts: "" });
    //   setComplexityObj(null)
    // }
  }

  const dataChange = (value) => {
    form.setFieldsValue({ estimateData: value });
    const getObj = props.estData.find(item => item.value === value)

    const estimateLevelObj = props.estimateLevelData.find(item => item.estimate_level === estimateLevelValue)
    if (getObj && estimateLevelObj && parseInt(getObj?.value) < parseInt(estimateLevelObj?.estimate_level)) {
      setShowInsuffData(true)
    } else {
      setShowInsuffData(false)
    }
    // if(value === "complex_system") {
    //   form.setFieldsValue({ estimates: "", days: "", cost: "", assemblies: "", sub_assemblies: "", parts: "" });
    //   setComplexityObj(null)
    // }
  }

  const onFieldsChange = (changedField, allFields) => {

    if (props.onCalculate) {
      let obj = {
        level: form.getFieldValue('level'),
        complexity: form.getFieldValue('complexity'),
        estimateData: form.getFieldValue('estimateData'),
      };
      props.onCalculate(obj);
    }

    // if(changedField.length > 0) {
    //   console.log(changedField[0].name, "name")
    //   if(changedField[0].value === "") {
    //     let name = changedField[0].name[0];
    //     console.log(complexityObj[name], "complexityObj[name].value()")
    //     form.setFieldsValue({ [name]: complexityObj[name] });
    //   }
    //   calculateValues();
    // }
  };

  const onFinish = (values) => {
    if (props.onCalculate) {
      props.onCalculate(values);
    }
  };
  const onFinishFailed = (errorInfo) => {
    // console.log('Failed:', errorInfo);
  };

  return (
    <FormContainer>
      <Form
        name="basic"
        form={form}
        layout="vertical"
        style={{
          // margin: "30px auto 0px",
          width: "100%",
        }}
        initialValues={{
          level: props.savedValues ? props.savedValues.level : 0,
          complexity: props.savedValues ? parseInt(props.savedValues.complexity) : null,
          estimateData: props.savedValues ? parseInt(props.savedValues.estimateData) : null
        }}
        onFieldsChange={onFieldsChange}
        onFinish={onFinish}
        onFinishFailed={onFinishFailed}
        autoComplete="off"
        requiredMark={false}
      >
        <Form.Item
          label="Estimate Level Required *"
          name="level"
          className="inputFormItem"
          rules={[
            {
              required: true,
              message: "Please select estimate level"
            }
          ]}>
          <Radio.Group onChange={onChange} className="radioLevelBtn">
            {
              props?.estimateLevelData.map((item, i) =>
                <Radio.Button value={item.estimate_level} key={i}>
                  <span style={{ display: "flex", flexDirection: "row", justifyContent: "center", gap: "10px" }}>
                    <Icon component={i === 0 ? ConceptImg : i === 1 ? ConceptImg2 : ConceptImg3} className="radioIcon" />
                    {item.name}
                  </span>
                </Radio.Button>
              )
            }
          </Radio.Group>
        </Form.Item>
        <Row gutter={[16, 16]} style={{ paddingTop: "10px" }}>
          <Col xs={24} md={12}>
            <Form.Item label="Product Complexity *" name="complexity" className="inputFormItem" style={{ marginTop: "12px" }}
              rules={[
                {
                  required: true,
                  message: "Please select complexity"
                }
              ]}
            >
              <Select onChange={complexityChange} className="selectInput" placeholder="Select Complexity">
                {
                  props?.complexityData.map((item, i) =>
                    <Select.Option value={item.id} key={i}>{item.complexity_name}</Select.Option>
                  )
                }
              </Select>
              {/* <Radio.Group onChange={complexityChange} className="radioGrpBtn">
                <Space direction="vertical" style={{ gap: 0 }}>
                  {
                    props?.complexityData.map((item, i) =>
                      <Radio value={item.id} key={i}>{item.complexity_name}</Radio>
                    )
                  }
                </Space>
              </Radio.Group> */}
            </Form.Item>
          </Col>
          <Col xs={24} md={12}>
            <div>
              <Carousel ref={carouselRef} dots={false} draggable={false} swipe={false} style={{ border: "1px solid #DBE2F1", borderRadius: "8px" }}>
                {
                  complexityObj && props?.complexityData.map((item, i) =>
                    <SliderImg key={i}>
                      {
                        item.s3_url && <span><img src={item.s3_url} alt={item.complexity_name} /></span>
                      }
                    </SliderImg>
                  )
                }
              </Carousel>
            </div>
          </Col>
        </Row>
        <Row gutter={[16, 16]} style={{ paddingTop: "40px" }}>
          <Col xs={24} md={12}>
            <Form.Item label="Data Available For Estimating *" name="estimateData" className="inputFormItem" style={{ marginTop: "12px" }}
              rules={[
                {
                  required: true,
                  message: "Please select data"
                }
              ]}
            >
              <Select onChange={dataChange} className="selectInput" placeholder="Select Data">
                {
                  props?.estData.map((item, i) =>
                    <Select.Option value={item.value} key={i}>{item.name}</Select.Option>
                  )
                }
              </Select>
              {/* <Radio.Group onChange={complexityChange} className="radioGrpBtn">
                <Space direction="vertical" style={{ gap: 0 }}>
                  {
                    props?.complexityData.map((item, i) =>
                      <Radio value={item.id} key={i}>{item.complexity_name}</Radio>
                    )
                  }
                </Space>
              </Radio.Group> */}
            </Form.Item>
          </Col>
          <Col xs={24} md={12}>
            <DataMsgWrapper>
              {
                form.getFieldValue("estimateData") &&
                <div>
                  <h4>{showInsuffData ? "Data insufficient to produce the estimate" : "Data matches selected Estimate Level"}</h4>
                </div>
              }
            </DataMsgWrapper>
          </Col>
        </Row>
        {/* <Form.Item className="calculateBtn">
          <Button htmlType="submit" icon={<RightOutlined />} disabled={!estimateLevelValue || complexityObj === null}>
            Calculate
          </Button>
        </Form.Item> */}
      </Form>
    </FormContainer>
  )
};