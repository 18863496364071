import { Button, Dropdown } from "antd";
import { Link, NavLink, useNavigate } from "react-router-dom"
import styled from "styled-components"
import * as palette from '../../styles/variables';
import Logo from "../../images/Logo.svg"
import { useEffect, useState } from "react";
import MenuIcon from "../../images/menu-right.png"

export const Navbar = () => {

  let navigate = useNavigate();

  const menuItems = [
    {
      key: '1',
      label: (
        <NavLink to="/services" className={({ isActive }) => isActive ? 'active' : ''}>PCO Services</NavLink>
      ),
    },
    {
      key: '2',
      label: (
        <NavLink to="/benefits" className={({ isActive }) => isActive ? 'active' : ''}>Benefits of Cost Estimation</NavLink>
      ),
    },
    {
      key: '3',
      label: (
        <NavLink to="/why-pco" className={({ isActive }) => isActive ? 'active' : ''}>Why PCO</NavLink>
      ),
    },
    {
      key: '4',
      label: (
        <NavLink to="/methodology" className={({ isActive }) => isActive ? 'active' : ''}>Methodology & Tools</NavLink>
      ),
    },
    {
      key: '5',
      label: (
        <NavLink to="/tiers" className={({ isActive }) => isActive ? 'active' : ''}>3 Tiers of Cost Estimation</NavLink>
      ),
    },
    {
      key: '6',
      label: (
        <Button type="primary" className="primaryBtn getQuoteBtn" onClick={() => navigate("/get-estimate")}>Get Quote</Button>
      ),
    },
    {
      key: '7',
      label: (
        <Button type="primary" className="primaryBtn" onClick={() => navigate("/contact-us")}>Contact Us</Button>
      ),
    },
    {
      key: '8',
      label: (
        <NavLink to="/privacy-policy" className={({ isActive }) => isActive ? 'active' : ''}>Privacy Policy</NavLink>
      ),
    },
    {
      key: '9',
      label: (
        <NavLink to="/terms-and-conditions" className={({ isActive }) => isActive ? 'active' : ''}>Terms & Conditions of Use</NavLink>
      ),
    },
  ]

  return (
    <NavbarContainer>
      <Link to="/" className="logoWrapper">
        <img src={Logo} alt="logo" />
        <h1>Product Cost Optimisation Ltd</h1>
      </Link>
      <Dropdown
        menu={{
          items: menuItems
        }}
        trigger={['click']}
        overlayClassName="headerMenu"
      >
        <span className="menuIcon">
          <img src={MenuIcon} alt="menu" />
        </span>
      </Dropdown>

      {/* <NavLinksWrapper>
        <NavLinkItem>
          <NavLink to="/services" className={({ isActive }) => isActive ? 'active' : ''}>PCO Services</NavLink>
        </NavLinkItem>
        <NavLinkItem>
          <NavLink to="/benefits" className={({ isActive }) => isActive ? 'active' : ''}>Benefits of Cost Estimation</NavLink>
        </NavLinkItem>
        <NavLinkItem>
          <NavLink to="/why-pco" className={({ isActive }) => isActive ? 'active' : ''}>Why PCO</NavLink>
        </NavLinkItem>
        <NavLinkItem>
          <NavLink to="/methodology" className={({ isActive }) => isActive ? 'active' : ''}>Methodology & Tools</NavLink>
        </NavLinkItem>
        <NavLinkItem>
          <NavLink to="/tiers" className={({ isActive }) => isActive ? 'active' : ''}>3 Tiers of Cost Estimation</NavLink>
        </NavLinkItem>
        <NavLinkItem>
          <NavLink to="/get-estimate" className={({ isActive }) => isActive ? 'active' : ''}>Get Quote</NavLink>
        </NavLinkItem>
        <NavLinkItem>
          <Button type="primary" className="primaryBtn" onClick={() => navigate("/contact-us")}>Contact Us</Button>
        </NavLinkItem>
      </NavLinksWrapper> */}
    </NavbarContainer>
  )
}

const NavbarContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 30px 80px;
  height: 84px;
  position: sticky;
  left: 0;
  top: 0px;
  right: 0;
  background: ${palette.WHITE_COLOR};
  z-index: 2;
  .logoWrapper {
    display: flex;
    align-items: center;
    justify-content: center;
    text-decoration: none;
    img {
      width: 100px;
    }
    h1 {
      margin-left: 8px;
      font-size: 18px;
      font-weight: 600;
      font-family: ${palette.FONT_FAMILY};
      color: ${palette.BLACK_COLOR};
    }
  }
  .menuIcon {
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    img {
      width: 28px;
      height: 20px;
    }
  }
  @media only screen and (max-width: 600px) {
    padding: 20px 30px;
  }
`;

const NavLinksWrapper = styled.ul`
  display: flex;
  flex-direction: column;
  gap: 22px;
`;

const NavLinkItem = styled.li`
  text-align: center;
  line-height: 20px;
  position: relative;
  a {
    text-decoration: none;
    color: ${palette.PRIMARY_BLUE};
    font-weight: 600;
    font-size: 14px;
    line-height: 19px;
    letter-spacing: 0.01em;
    padding-bottom: 2px;
    text-align: center;
  }
  .active::after {
    content: '';
    display: block;
    width: 46px;
    height: 2px;
    background: ${palette.PRIMARY_BLUE};
    position: absolute;
    position: absolute;
    left: 50%;
    bottom: -8px;
    transform: translateX(-50%);
  }
  .primaryBtn {
    height: 46px;
    padding: 12px 28px;
    font-size: 14px !important;
    font-weight: 600 !important;
  }
`;
