import { Button, Col, Form, Input, Modal, Row } from "antd"
import styled from "styled-components"
import * as palette from '../../styles/variables';
import styledComponents from 'styled-components';
import { useState } from "react";
import DomainImg from "../../images/quotulator/domain_verification.svg"
import { contactUs } from "../../utils/services/quotulator";

export const ContactPage = () => {

  const [form] = Form.useForm();

  const { TextArea } = Input;

  const [apiLoading, setApiLoading] = useState(false)

  const [isModalOpen, setIsModalOpen] = useState(false);
  const showModal = () => {
    setIsModalOpen(true);
  };
  const handleOk = () => {
    setIsModalOpen(false);
  };
  const handleCancel = () => {
    setIsModalOpen(false);
  };

  const onFinish = (values) => {
    const formData1 = new FormData();

    formData1.append('name', values.name);
    formData1.append('mobile', values.phone);
    formData1.append('email', values.email);
    formData1.append('company_name', values.company);
    if (values.message) {
      formData1.append('message', values.message);
    } else {
      formData1.append('message', null);
    }

    setApiLoading(true)

    contactUs(
      formData1,
      success => {
        setApiLoading(false)
        showModal();
        form.resetFields();
      },
      errorMessages => {
        setApiLoading(false)
      }
    );
  }

  return (
    <Container>
      <Row className="mobRow">
        <Col xs={24} md={12} className="mobCol" style={{ display: "flex", alignItems: "center" }}>
          <ContentContainer>
            <h2>Contact Us</h2>
            <Form
              form={form}
              initialValues={{

              }}
              labelCol={{ span: 24 }}
              // wrapperCol={{xs: { span: 20},}}
              className="contactForm"
              requiredMark={false}
              onFinish={onFinish}
            >
              <FormItems>
                <Form.Item
                  label="Name"
                  name="name"
                  rules={[
                    {
                      required: true,
                      message: 'Please enter name!',
                    },
                  ]}>
                  <Input placeholder="Enter your name" />
                </Form.Item>
                <Form.Item
                  label="Company Name"
                  name="company"
                  rules={[
                    {
                      required: true,
                      message: 'Please enter company name!',
                    },
                  ]}>
                  <Input placeholder="Enter your company name" />
                </Form.Item>
              </FormItems>
              <FormItems>
                <Form.Item
                  label="Phone"
                  name="phone"
                  rules={[
                    {
                      required: true,
                      // pattern: /^[\+]?[(]?[0-9]{3}[)]?[-\s\.]?[0-9]{3}[-\s\.]?[0-9]{4,6}$/im,
                      // pattern: /^(?:(?:\+|00)([1-9]|[1-9][0-9]|[1-9][0-9][0-9])\s?)?(\(?[0-9]*\)?)?[0-9_\- \(\)]*$/,
                      validator: (_, value) => {
                        // Regular expression to match phone numbers with or without country codes and parentheses
                        const phoneRegex = /^(?:(?:\+|00)([1-9]|[1-9][0-9]|[1-9][0-9][0-9])\s?)?(\(?[0-9]*\)?)?[0-9_\- \(\)]*$/;

                        // Check if the phone number matches the regex pattern
                        const isValidFormat = phoneRegex.test(value);

                        // Check if the length is at least 10 characters
                        const isLengthValid = value.replace(/\D/g, '').length >= 10;

                        // Return validation result based on format and length
                        if (!isValidFormat || !isLengthValid) {
                          return Promise.reject('Please enter a valid phone number with at least 10 digits');
                        }
                        return Promise.resolve();
                      },
                      message: 'Please enter correct phone number!',
                    },
                    // {
                    //   required: true,
                    //   message: 'Please enter phone number!',
                    // },
                  ]}>
                  <Input placeholder="Enter your phone number" minLength={10} maxLength={16} />
                </Form.Item>
                <Form.Item
                  label="Email"
                  name="email"
                  rules={[
                    {
                      required: true,
                      message: 'Please enter your email!',
                      type: "email"
                    },
                  ]}>
                  <Input placeholder="Enter your email" />
                </Form.Item>
              </FormItems>
              <Form.Item label="Message (Optional)" name="message" style={{ flex: 2 }}>
                <TextArea rows={4} placeholder="Enter your message" style={{ height: "auto", resize: "none" }} />
              </Form.Item>
              <FormItemsSubmit>
                <Form.Item style={{ marginBottom: 0 }}>
                  <Button type="primary" className="primaryBtn" htmlType="submit" disabled={apiLoading}>
                    Send
                  </Button>
                </Form.Item>
              </FormItemsSubmit>
            </Form>
          </ContentContainer>
        </Col>
        <Col xs={24} md={12} className="mobCol" style={{ display: "flex", alignItems: "center", justifyContent: "center" }}>
          <MapSection>
            <iframe title="location" src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d2484.673949582022!2d-3.171928484426213!3d51.482498879631045!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x486e1dec40d8271b%3A0x76edb0038413ac31!2sBrunel%20House!5e0!3m2!1sen!2sin!4v1678855506735!5m2!1sen!2sin" style={{ border: 0 }} allowfullscreen="" loading="lazy" referrerpolicy="no-referrer-when-downgrade"></iframe>
          </MapSection>
        </Col>
      </Row>
      <Modal
        title=""
        open={isModalOpen}
        onOk={handleOk}
        onCancel={handleCancel}
        footer={null}
        // closable={false}
        centered
        maskClosable={false}
        width="578px"
      >
        <ModalContentWrapper>
          <img src={DomainImg} alt="thanks" />
          <h2>Thank you for reaching out.</h2>
          <p>We promise to get back shortly.</p>
        </ModalContentWrapper>
      </Modal>
    </Container>
  )
}

const Container = styled.section`
  padding: 60px 80px 40px 80px;
  flex: 1;
  width: 100%;
  max-width: 1400px;
  margin: 0 auto;
  @media only screen and (max-width: 768px) {
    padding: 45px 30px 30px 30px;
    .mobRow {
      flex-direction: column;
      gap: 30px;
    }
    .mobCol {
      max-width: 100% !important;
    }
  }
`;

const ContentContainer = styled.div`
  width: 100%;
  margin-right: 35px;
  @media only screen and (max-width: 768px) {
    margin-right: 0px;
  }
  h2 {
    font-weight: 600;
    font-size: 28px;
    line-height: 38px;
    color: ${palette.WHITE_COLOR};
    margin-bottom: 16px;
  }
  p {
    margin-bottom: 28px;
    span {
    font-weight: 600;
    color: ${palette.WHITE_COLOR};
    }
  }
  .primaryBtn {
    padding: 8px 70px;
    height: 49px;
  }
  .contactForm {
    .ant-form-item .ant-form-item-label >label {
      font-size: 14px;
      font-weight: 600;
      color: ${palette.WHITE_COLOR};
    }
    .ant-input::placeholder {
      color: ${palette.WHITE_COLOR};
    }
    .ant-input {
      height: 46px;
      border: 1px solid #DEDEDE;
      color: ${palette.WHITE_COLOR};
      padding: 12px 16px;
      border-radius: 0px;
      background-color: rgba(255, 255, 255, 0.5);
      backdrop-filter: blur(5px);
      &:focus {
        border: 1px solid #DEDEDE;
      }
    }
  }
`;

const FormItems = styled.div`
  display: flex;
  align-items: center;
  gap: 30px;
  .ant-form-item {
    width: 50%;
  }
  @media only screen and (max-width: 600px) {
    gap: 0px;
    flex-direction: column;
    .ant-form-item {
      width: 100%;
    }
  }
`;

const FormItemsSubmit = styled.div`
  display: flex;
  justify-content: flex-end;
  gap: 30px;
`;

const MapSection = styled.div`
  border-radius: 8px;
  width: 100%;
  height: 100%;
  overflow: hidden;
  padding: 4px;
  background: ${palette.WHITE_COLOR};
  iframe {
    width: 100%;
    height: 100%;
    border-radius: 8px;
  }
  @media only screen and (max-width: 768px) {
    height: 350px;
  }
  @media only screen and (max-width: 600px) {
    height: 250px;
  }
`;

const ModalContentWrapper = styledComponents.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 20px 30px;
  img {
    width: 100px;
    height: 100px;
  }
  h2 {
    font-family: ${palette.FONT_FAMILY};
    font-weight: 600;
    font-size: 24px;
    line-height: 30px;
    color: #1D273E;
    text-align: center;
    margin-bottom: 12px;
  }
  p {
    font-family: ${palette.FONT_FAMILY};
    font-weight: 500;
    font-size: 16px;
    line-height: 24px;
    color: #949494;
    text-align: center;
    margin-bottom: 30px;
  }
  .ant-btn {
    width: 166px;
    height: 47px;
  }
`;
