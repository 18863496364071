import { Outlet } from "react-router"
import { Navigate } from "react-router-dom"

export const UnprotectedRouteLayout = () => {
  const token = sessionStorage.getItem('token');

  if (token) {
    return <Navigate to="/quotulator" />
  }

  return <Outlet />
}
