import { Button, Col, Row } from "antd"
import styled from "styled-components"
import * as palette from '../../styles/variables';
import BannerImg from '../../images/GetEstimate.png'
import { useNavigate } from "react-router-dom";

export const GetEstimatePage = () => {

  let navigate = useNavigate();

  return (
    <Container>
      <ContentContainer>
        <h2>Get Quote</h2>
        <p>Get Quote in 3 Simple Steps</p>
        <ul className="listWrapper">
          <li>
            <span>1</span>
            Log on to the portal
          </li>
          <li>
            <span>2</span>Fill the product details
          </li>
          <li>
            <span>3</span>
            Get Quote Instantly
          </li>
        </ul>
        <Button type="primary" className="primaryBtn" onClick={() => { sessionStorage.removeItem('token'); sessionStorage.removeItem('userId'); navigate('/signin') }}>Get Estimate Now</Button>
      </ContentContainer>
      {/* <Row>
        <Col span={11} style={{display: "flex", alignItems: "center"}}>
          <ContentContainer>
            <h2>Get Quote in 3 Simple Steps</h2>
            <ul className="listWrapper">
              <li>Log on to the portal</li>
              <li>Fill the product details</li>
              <li>Get Quote Instantly</li>
            </ul>
            <Button type="primary" className="primaryBtn" onClick={() => {sessionStorage.removeItem('token'); sessionStorage.removeItem('userId'); navigate('/signin')}}>Get estimate now</Button>
          </ContentContainer>
        </Col>
        <Col span={13} style={{display: "flex", alignItems: "center", justifyContent: "center"}}>
          <div>
            <img src={BannerImg} alt="home" />
          </div>
        </Col>
      </Row> */}
    </Container>
  )
}

const Container = styled.section`
  padding: 60px 40px 40px 66px;
  width: 100%;
  @media only screen and (max-width: 768px) {
    padding: 45px 30px 30px 30px;
  }
`;

const ContentContainer = styled.div`
  max-width: 350px;
  margin: 0 auto;
  display: flex;
  flex-direction: column;
  align-items: center;
  @media only screen and (max-width: 768px) {
    max-width: 100%;
  }
  h2 {
    font-weight: 600;
    font-size: 28px;
    line-height: 38px;
    color: ${palette.WHITE_COLOR};
    margin-bottom: 24px;
    text-align: center;
  }
  p {
    font-weight: 500;
    font-size: 18px;
    line-height: 30px;
    color: ${palette.WHITE_COLOR};
    margin-bottom: 20px;
    text-align: center;
  }
  .listWrapper {
    margin-top: 25px;
    li {
      padding: 9px 40px 9px 65px;
      font-family: ${palette.FONT_FAMILY};
      font-style: normal;
      font-weight: 500;
      font-size: 16px;
      line-height: 30px;
      color: ${palette.WHITE_COLOR};
      border-radius: 26px;
      border: 1px solid #DE4949;
      margin-bottom: 70px;
      text-align: center;
      position: relative;
      span {
        width: 50px;
        height: 50px;
        background: #DE4949;
        border-radius: 100%;
        display: inline-flex;
        align-items: center;
        justify-content: center;
        position: absolute;
        left: 0px;
        top: -1px;
        bottom: 0;
      }
      &:after {
        content: "";
        width: 1px;
        height: 56px;
        display: inline-block;
        background: #DE4949;
        position: absolute;
        bottom: -63px;
        left: 50%;
      }
      &:last-child {
        margin-bottom: 20px;
        &:after {
          display: none;
        }
      }
    }
  }
  .primaryBtn {
    margin-top: 30px;
    padding: 12px 26px;
  }
`;
