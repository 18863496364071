import { useEffect, useState } from 'react';
import { Button, Col, Form, Input, InputNumber, Modal, Row, Steps } from 'antd';
import styledComponents from 'styled-components';
import { InputLevel } from './inputLevel';
import { ResultsForm } from './results';
import Logo from "../../images/TextLogo.svg"
import { Link, useNavigate } from 'react-router-dom';
import { getAllSettingsValues, getComplexity, getEstimateData, getEstimateLevel, requestQuotation } from '../../utils/services/quotulator';
import { UploadFiles } from './uploadFiles';
import DomainImg from "../../images/quotulator/domain_verification.svg"
import * as palette from '../../styles/variables';
import { useImmer } from 'use-immer';
import { Loader } from '../../components/Loader';
import { FormWrapper, ResultsFormContainer, ResultsList, TotalCostWrapper } from './styles';
import PaymentImg from '../../images/quotulator/payments.svg'

export const Quotulator = () => {

  let navigate = useNavigate();

  const [current, setCurrent] = useState(0);

  const [formData, setFormData] = useState(null)

  const [estLevelData, setEstLevelData] = useState([]);

  const [complexityData, setComplexityData] = useState([]);

  const [estData, setEstData] = useState([]);

  const [settingsData, setSettingsData] = useState(null);

  const [savedValues, setSavedValues] = useState(null);

  const [prevStateValues, setPrevStateValues] = useState(null);

  const [loaders, updateLoaders] = useImmer({
    levelLoading: false,
    compLoading: false,
    settLoading: false,
    dataLoading: false
  });

  const [complexityObj, setComplexityObj] = useState(null);

  const [estimateLevelObj, setEstimateLevelObj] = useState(null);

  const [state, updateState] = useImmer({
    level: 0,
    assemblies: 0,
    sub_assemblies: 0,
    parts: 0,
    estimates: 0,
    cost: 0,
    days: 0,
    estimateData: 0
  });

  const [productState, updateProductState] = useImmer({
    description: "",
    part_number: ""
  });

  const [apiLoading, setApiLoading] = useState(false)

  const [showInsuffData, setShowInsuffData] = useState(false)

  const [isModalOpen, setIsModalOpen] = useState(false);
  const showModal = () => {
    setIsModalOpen(true);
  };
  const handleOk = () => {
    setIsModalOpen(false);
  };
  const handleCancel = () => {
    setIsModalOpen(false);
  };

  useEffect(() => {
    updateLoaders(draft => {
      draft.levelLoading = true
      draft.compLoading = true
      draft.settLoading = true
      draft.dataLoading = true
    });
    getEstimateLevel(
      (successdata) => {
        if (successdata.data) {
          setEstLevelData(successdata.data)
        }
        updateLoaders(draft => {
          draft.levelLoading = false
        });
      },
      error => {
        updateLoaders(draft => {
          draft.levelLoading = false
        });
      }
    )

    getComplexity(
      (successdata) => {
        if (successdata.data) {
          setComplexityData(successdata.data)
        }
        updateLoaders(draft => {
          draft.compLoading = false
        });
      },
      error => {
        updateLoaders(draft => {
          draft.compLoading = false
        });
      }
    )

    getEstimateData(
      (successdata) => {
        if (successdata.data) {
          setEstData(successdata.data)
        }
        updateLoaders(draft => {
          draft.dataLoading = false
        });
      },
      error => {
        updateLoaders(draft => {
          draft.dataLoading = false
        });
      }
    )

    getAllSettingsValues(
      (successdata) => {
        if (successdata?.data.length > 0) {
          setSettingsData(successdata.data[0])
        }
        updateLoaders(draft => {
          draft.settLoading = false
        });
      },
      error => {
        updateLoaders(draft => {
          draft.settLoading = false
        });
      }
    )
  }, [])

  const onCalculate = values => {
    const getObj = complexityData.find(item => item.id === parseInt(values.complexity))
    if (getObj) {
      setComplexityObj(getObj ? getObj : null);
      updateState(draft => {
        draft.assemblies = getObj.assemblies + getObj.sub_assemblies
        draft.sub_assemblies = getObj.sub_assemblies
        draft.parts = getObj.no_parts
      });
    }
    setFormData(values)
    setSavedValues(values)
  }

  useEffect(() => {
    if (formData || formData?.level || formData?.estimateData) {
      updateState(draft => {
        draft.level = formData.level;
        draft.estimateData = formData?.estimateData
      });

      const getLevelObj = estLevelData.find(item => item.estimate_level === formData.level)
      if (getLevelObj) {
        setEstimateLevelObj(getLevelObj)
      }

      const getObj = complexityData.find(item => item.id === parseInt(formData.complexity))
      if (getObj) {
        setComplexityObj(getObj ? getObj : null);
        updateState(draft => {
          draft.assemblies = getObj.assemblies + getObj.sub_assemblies
          draft.sub_assemblies = getObj.sub_assemblies
          draft.parts = getObj.no_parts
        });
        let statObj = {
          assemblies: getObj.assemblies,
          sub_assemblies: getObj.sub_assemblies,
          parts: getObj.no_parts
        };
        // calculateValues(statObj);
      }
    }
  }, [formData])
  useEffect(() => {
    calculateValues(state)
  }, [state])

  const calculateValues = (val) => {

    const getLevelObj = estLevelData.find(item => item.estimate_level === formData?.level)
    if (getLevelObj) {
      setEstimateLevelObj(getLevelObj)
    }

    const getObj = complexityData.find(item => item.id === parseInt(formData?.complexity))
    if (getObj) {
      setComplexityObj(getObj ? getObj : null);
    }

    const estimateDataObj = estData.find(item => item.value === formData?.estimateData)
    if (getLevelObj && estimateDataObj && parseInt(getLevelObj?.estimate_level) > parseInt(estimateDataObj?.value)) {
      setShowInsuffData(true)
    } else {
      setShowInsuffData(false)
    }

    let paramObj = {
      assemblies: val?.assemblies ? val?.assemblies : getObj?.assemblies,
      sub_assemblies: val?.sub_assemblies ? val?.sub_assemblies : getObj?.sub_assemblies,
      parts: val?.parts ? val?.parts : getObj?.no_parts
    }

    let totalMinutes = parseFloat(settingsData?.setup_job) + parseFloat(settingsData?.build_bom_base) + parseFloat(settingsData?.report_to_client_base) + parseFloat(settingsData?.quality_assurance_base);

    if (paramObj && !getObj?.response && !getLevelObj?.response && parseInt(getLevelObj?.estimate_level) <= parseInt(estimateDataObj?.value)) {
      let total = parseFloat(paramObj?.assemblies) + parseFloat(paramObj.parts);
      // if (parseInt(paramObj?.assemblies) === 0 && parseInt(paramObj?.sub_assemblies) === 0) {
      //   total = parseInt(paramObj.parts)
      // } else if (parseInt(paramObj?.assemblies) === 0 && parseInt(paramObj?.sub_assemblies) !== 0) {
      //   total = parseInt(paramObj?.sub_assemblies) + parseInt(paramObj.parts)
      // } else {
      //   total = (parseInt(paramObj.assemblies) + parseInt(paramObj.sub_assemblies)) + (parseInt(paramObj.assemblies) * parseInt(paramObj.sub_assemblies) * parseInt(paramObj.parts))
      // }

      let totalMinutesPerEstimate = (parseFloat(settingsData?.job_evalution) * total) + (parseFloat(settingsData?.build_bom_estimate) * total) + (estimateDataObj?.estimate * total * parseFloat(settingsData?.process_component)) + (parseFloat(settingsData?.report_to_client_estimate) * total) + (parseFloat(settingsData?.make_estimate) * total * parseFloat(settingsData?.process_component)) + (parseFloat(settingsData?.quality_assurance_estimate) * total)

      let allTotalMinutes = totalMinutes + totalMinutesPerEstimate

      let comMultiplier = allTotalMinutes * parseFloat(getObj?.bom_build_multiplier);

      let getLevelObj1 = estLevelData.find(item => item.estimate_level === formData?.level)

      let estLevelMultiplier = comMultiplier * parseFloat(getLevelObj1?.multiplier);

      let contigencyVal1 = estLevelMultiplier * (parseFloat(settingsData?.contingency) / 100);

      let efficiencyVal1 = ((estLevelMultiplier / (parseFloat(settingsData?.efficiency) / 100)) - estLevelMultiplier);

      let totalHrs = (contigencyVal1 + estLevelMultiplier + efficiencyVal1) / 60;

      let totalRate = totalHrs * parseFloat(settingsData?.rate);

      let totaldays = totalHrs / 8;

      updateState(draft => {
        draft.estimates = total
        draft.days = Math.ceil(totaldays)
        draft.cost = totalRate.toFixed(2)
      });

      setPrevStateValues({
        assemblies: paramObj.assemblies,
        sub_assemblies: paramObj.sub_assemblies,
        parts: paramObj.parts,
        estimates: total,
        days: totaldays.toFixed(),
        cost: totalRate.toFixed(2)
      })

    } else {
      updateState(draft => {
        draft.assemblies = 0
        draft.sub_assemblies = 0
        draft.parts = 0
        draft.estimates = 0
        draft.days = 0
        draft.cost = 0
      });
    }
  }


  const [allFiles, setAllFiles] = useState([])
  const getDocs = (docs) => {
    setAllFiles(docs)
  }

  const [allFileDrawings, setAllFileDrawings] = useState([])
  const getDrawings = (docs) => {
    setAllFileDrawings(docs)
  }

  const reqQuotation = () => {
    const formData1 = new FormData();

    allFiles.length > 0 && allFiles.map((file, index) => {
      formData1.append(`uploadFile`, file.originFileObj);
    });

    allFileDrawings.length > 0 && allFileDrawings.map((file, index) => {
      formData1.append(`drawingFile`, file.originFileObj);
    });

    formData1.append('customer_id', sessionStorage.getItem("userId"));
    formData1.append('estimate_level', formData.level);
    formData1.append('complexity', formData.complexity);
    formData1.append('assemblies', prevStateValues.assemblies);
    // formData1.append('sub_assemblies', prevStateValues.sub_assemblies);
    formData1.append('parts', prevStateValues.parts);
    formData1.append('total_work_package', parseInt(prevStateValues.estimates));
    formData1.append('package_cost', parseInt(prevStateValues.cost));
    formData1.append('days', parseInt(prevStateValues.days));
    formData1.append('estimate_data', formData.estimateData);
    if (productState.description) {
      formData1.append('description', productState.description);
    }
    if (productState.part_number) {
      formData1.append('part_number', productState.part_number);
    }

    setApiLoading(true)

    requestQuotation(
      formData1,
      success => {
        setApiLoading(false)
        showModal();
      },
      errorMessages => {
        setApiLoading(false)
      }
    );
  }

  return (
    <>
      {
        (loaders.levelLoading && loaders.compLoading && loaders.settLoading && loaders.dataLoading) ? <Loader /> :
          <div className="quoWrapper">
            {
              current === 0 &&
              <>
                <Row>
                  <Col xs={24} md={12}>
                    <div>
                      <div className="productInput">
                        <label>Product Description</label>
                        <Input placeholder="Enter product description" value={productState.description}
                          onChange={(e) => {
                            updateProductState(draft => {
                              draft.description = e.target.value
                            });
                          }}
                        />
                      </div>
                      <div className="productInput">
                        <label>Product Part Number</label>
                        <Input placeholder="Enter product part number" value={productState.part_number}
                          onChange={(e) => {
                            updateProductState(draft => {
                              draft.part_number = e.target.value
                            });
                          }}
                        />
                      </div>
                      <InputLevel savedValues={savedValues} onCalculate={onCalculate} estimateLevelData={estLevelData} complexityData={complexityData} estData={estData} />
                    </div>
                  </Col>
                  <Col xs={24} md={12}>
                    <ResultsFormContainer>
                      <h2 className="calTitle">Work Package Calculation</h2>
                      <Row className="headerRow">
                        <Col xs={8} md={10}>
                          {/* <h2>Your Results</h2> */}
                        </Col>
                        <Col xs={16} md={14} style={{ display: "flex" }}>
                          <h3>Default</h3>
                          <h3 style={{ flex: 2 }}>Modify</h3>
                          <h3>Result</h3>
                        </Col>
                      </Row>
                      <FormWrapper>
                        <Row>
                          <Col xs={8} md={10}>
                            <label>Number of Assemblies (Main Assemblies + Sub-Assemblies) in Work Package</label>
                          </Col>
                          <Col xs={16} md={14}>
                            <ResultsList>
                              <div style={{ flex: 1, display: "flex", alignItems: "center", justifyContent: "center" }}>
                                <InputNumber disabled value={complexityObj?.assemblies + complexityObj?.sub_assemblies} className="bgInput" />
                              </div>

                              <div style={{ display: "flex", alignItems: "center", justifyContent: "center", gap: "12px", flex: 2 }}>
                                <Button shape="circle" className="circleBtn"
                                  onClick={() => {
                                    if (state.assemblies > 0) {
                                      updateState(draft => {
                                        draft.assemblies = state.assemblies - 1
                                      });
                                    }
                                  }}
                                >-</Button>
                                <InputNumber
                                  min={0}
                                  value={state.assemblies}
                                  onChange={(val) => {
                                    updateState(draft => {
                                      draft.assemblies = val
                                    });
                                  }}
                                />
                                <Button shape="circle" className="circleBtn"
                                  onClick={() => {
                                    updateState(draft => {
                                      draft.assemblies = state.assemblies + 1
                                    });
                                  }}
                                >+</Button>
                              </div>
                              <div style={{ flex: 1, display: "flex", alignItems: "center", justifyContent: "center" }}>
                                <InputNumber
                                  disabled value={state.assemblies}
                                  className="bgInput"
                                />
                              </div>
                            </ResultsList>
                          </Col>
                        </Row>
                        {/* <Row>
                          <Col xs={8} md={10}>
                            <label>Number of  Sub - Assemblies Per Item</label>
                          </Col>
                          <Col xs={16} md={14}>
                            <ResultsList>
                              <div style={{ flex: 1, display: "flex", alignItems: "center", justifyContent: "center" }}>
                                <InputNumber disabled value={complexityObj?.sub_assemblies} className="bgInput" />
                              </div>
                              <div style={{ display: "flex", alignItems: "center", justifyContent: "center", gap: "12px", flex: 2 }}>
                                <Button shape="circle" className="circleBtn"
                                  onClick={() => {
                                    if (state.sub_assemblies > 0) {
                                      updateState(draft => {
                                        draft.sub_assemblies = state.sub_assemblies - 1
                                      });
                                    }
                                  }}
                                >-</Button>
                                <InputNumber disabled value={state.sub_assemblies} />
                                <Button shape="circle" className="circleBtn"
                                  onClick={() => {
                                    updateState(draft => {
                                      draft.sub_assemblies = state.sub_assemblies + 1
                                    });
                                  }}
                                >+</Button>
                              </div>
                              <div style={{ flex: 1, display: "flex", alignItems: "center", justifyContent: "center" }}>
                                <InputNumber value={state.sub_assemblies} min={0}
                                  onChange={(val) => {
                                    updateState(draft => {
                                      draft.sub_assemblies = val
                                    });
                                  }}
                                  className="bgInput"
                                />
                              </div>
                            </ResultsList>
                          </Col>
                        </Row> */}
                        <Row>
                          <Col xs={8} md={10}>
                            <label>Approximate Number of Parts in Work Package</label>
                          </Col>
                          <Col xs={16} md={14}>
                            <ResultsList>
                              <div style={{ flex: 1, display: "flex", alignItems: "center", justifyContent: "center" }}>
                                <InputNumber disabled value={complexityObj?.no_parts} className="bgInput" />
                              </div>

                              <div style={{ display: "flex", alignItems: "center", justifyContent: "center", gap: "12px", flex: 2 }}>
                                <Button shape="circle" className="circleBtn"
                                  onClick={() => {
                                    if (state.parts > 0) {
                                      updateState(draft => {
                                        draft.parts = state.parts - 1
                                      });
                                    }
                                  }}
                                >-</Button>
                                <InputNumber value={state.parts} min={0}
                                  onChange={(val) => {
                                    updateState(draft => {
                                      draft.parts = val
                                    });
                                  }}

                                />
                                {/* <span>{state.parts}</span> */}
                                <Button shape="circle" className="circleBtn"
                                  onClick={() => {
                                    updateState(draft => {
                                      draft.parts = state.parts + 1
                                    });
                                  }}
                                >+</Button>
                              </div>
                              <div style={{ flex: 1, display: "flex", alignItems: "center", justifyContent: "center" }}>
                                <InputNumber disabled value={state.parts} className="bgInput" />
                              </div>
                            </ResultsList>
                          </Col>
                        </Row>
                        <Row>
                          <Col xs={8} md={10}>
                            <label>Number of Estimates in Work Package</label>
                          </Col>
                          <Col xs={16} md={14}>
                            <ResultsList>
                              <div style={{ flex: 1, visibility: "hidden" }}>
                                <Input disabled />
                              </div>
                              <div style={{ display: "flex", alignItems: "center", justifyContent: "center", gap: "12px", flex: 2, visibility: "hidden" }}>
                                <Button shape="circle" className="circleBtn">-</Button>
                                <span>0</span>
                                <Button shape="circle" className="circleBtn">+</Button>
                              </div>
                              <div style={{ flex: 1, display: "flex", alignItems: "center", justifyContent: "center" }}>
                                <InputNumber disabled value={state.estimates} className="bgInput" />
                              </div>
                            </ResultsList>
                          </Col>
                        </Row>
                        <Row>
                          <Col xs={8} md={10}>
                            <label>Expected Work Package Delivery (Days)</label>
                          </Col>
                          <Col xs={16} md={14}>
                            <ResultsList>
                              <div style={{ flex: 1, visibility: "hidden" }}>
                                <Input disabled />
                              </div>
                              <div style={{ display: "flex", alignItems: "center", justifyContent: "center", gap: "12px", flex: 2, visibility: "hidden" }}>
                                <Button shape="circle" className="circleBtn">-</Button>
                                <span>0</span>
                                <Button shape="circle" className="circleBtn">+</Button>
                              </div>
                              <div style={{ flex: 1, display: "flex", alignItems: "center", justifyContent: "center" }}>
                                <InputNumber disabled value={state.days} className="bgInput" />
                              </div>
                            </ResultsList>
                          </Col>
                        </Row>
                      </FormWrapper>
                      <TotalCostWrapper>
                        {
                          showInsuffData ? <h5 style={{ color: "red", textTransform: "uppercase" }}>Data insufficient to produce the estimate</h5> :
                            <>
                              {/* <img src={PaymentImg} alt="cost" /> */}
                              <h5>Budget Work Package Cost</h5>
                              <h4>€ {state.cost !== "NaN" ? state.cost : 0}</h4>
                            </>
                        }
                      </TotalCostWrapper>
                    </ResultsFormContainer>
                    {/* <ResultsForm formData={savedValues} estimateLevelData={estLevelData} complexityData={complexityData} settingsData={settingsData} prevValues={prevStateValues} getOutput={(data) => setPrevStateValues(data)} /> */}
                  </Col>
                </Row>
              </>
            }
            {
              current === 1 && <UploadFiles getDocs={getDocs} getDrawings={getDrawings} />
            }
            <BottomContainer>
              {/* <Link to="/" className="logoWrapper">
              <img src={Logo} alt="logo" />
            </Link> */}
              <div className='buttonNext'>
                {
                  current === 0 &&
                  <Button type="primary" className='primaryBtn' style={{ height: "46px", padding: "0px 40px" }} onClick={() => setCurrent(current + 1)} disabled={!formData?.level || !formData?.complexity || complexityObj?.response || estimateLevelObj?.response || parseInt(formData?.estimateData) < parseInt(formData?.level)}>Next</Button>
                }
                {
                  current === 1 &&
                  <div className="submitBtnGroup">
                    <Button className="outlinedBtn" style={{ height: "46px", padding: "0px 40px" }} onClick={() => setCurrent(current - 1)}>Back</Button>
                    <Button type="primary" className='primaryBtn' style={{ height: "46px", padding: "0px 40px" }} onClick={reqQuotation} disabled={apiLoading}>Request Quotation</Button>
                  </div>
                }
              </div>
              <div className="estStepWrapper">
                <Steps
                  className='estimateSteps'
                  current={current}
                  items={[
                    {
                      title: '',
                      icon: <span>1</span>
                    },
                    {
                      title: '',
                      icon: <span>2</span>
                    }
                  ]}
                />
              </div>
            </BottomContainer>
            <Modal
              title=""
              open={isModalOpen}
              onOk={handleOk}
              onCancel={handleCancel}
              footer={null}
              closable={false}
              centered
              maskClosable={false}
              width="578px"
            >
              <ModalContentWrapper>
                <img src={DomainImg} alt="thanks" />
                <h2>Thanks for sharing the information</h2>
                <p>We will get back to you as soon as possible with your quotation</p>
                <Button type="primary" className='primaryBtn' onClick={() => { sessionStorage.removeItem('token'); sessionStorage.removeItem('userId'); navigate("/") }}>Home</Button>
              </ModalContentWrapper>
            </Modal>
          </div>
      }
    </>
  )
}

const DefaultValue = styledComponents.span`
padding: 4px 11px;
border-radius: 6px;
display: flex;
align-items: center;
justify-content: center;
color: rgba(0, 0, 0, 0.5);
background-color: rgba(0, 0, 0, 0.04);
border: 1px solid #d9d9d9;
box-shadow: none;
cursor: not-allowed;
min-width: 50px;
`;

const BottomContainer = styledComponents.div`
  display: flex;
  align-items: center;
  padding: 40px 28px 16px;
  width: 100%;
  position: relative;
  .logoWrapper {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    text-decoration: none;
    flex: 1;
    img {
      width: 280px;
    }
  }
  .buttonNext {
    flex: 1;
    text-align: center;
  }
  .submitBtnGroup {
    display: flex;
    align-items: center;
    gap: 12px;
    justify-content: center;
    .outlinedBtn {
      border-radius: 0px !important;
    }
  }
  @media only screen and (max-width: 600px) {
    padding: 20px 28px 16px;
    flex-direction: column;
    .submitBtnGroup {
      flex-direction: column;
      button {
        width: 100%;
      }
    }
  }
`;

const ModalContentWrapper = styledComponents.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 20px 30px;
  img {
    width: 100px;
    height: 100px;
  }
  h2 {
    font-family: ${palette.FONT_FAMILY};
    font-weight: 600;
    font-size: 24px;
    line-height: 30px;
    color: #1D273E;
    text-align: center;
    margin-bottom: 12px;
  }
  p {
    font-family: ${palette.FONT_FAMILY};
    font-weight: 500;
    font-size: 16px;
    line-height: 24px;
    color: #949494;
    text-align: center;
    margin-bottom: 30px;
  }
  .ant-btn {
    width: 166px;
    height: 47px;
  }
`;
