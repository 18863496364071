import styled from 'styled-components';
import { AppWrapper } from '../../App';
import { Navbar } from '../../components/navigation';
import * as palette from '../../styles/variables';

export const TermsPage = () => {
  return (
    <AppWrapper>
      <Navbar />
      <main className='mainWrapper' id="mainContainer" style={{ paddingTop: "0px", display: "block" }}>
        <Container>
          <div className='wrapper'>
            <h2>Terms & Conditions of Use</h2>
            {/* <div className='lastUpdated'>
              Last updated on: 16 Jun 2021
            </div> */}
            <div>
              <h3>1. ACCEPTANCE</h3>
              <p>Your access to and use of this Site is subject to the following terms and conditions and all applicable laws. By accessing and browsing this Site, you accept, without limitation or qualification, these Terms and Conditions and acknowledge that any other agreements regarding the use of this Site between you and Product Cost Optimisation (“PCO”) are superseded and of no force or effect.</p>
              <h3>2. PRODUCT AND RELATED INFORMATION</h3>
              <p>Product or any related information on this Site provided by PCO is intended for general information purposes only.</p>
              <h3>3. TRADEMARKS/PROPRIETARY RIGHTS</h3>
              <p>You should assume that all product names appearing on this Site, whether or not appearing in large print or with the trademark symbol are trademarks of PCO, or its affiliates and/or other parties. This Site may also contain or reference patents, proprietary information, technologies, products, processes or other proprietary rights of PCO, or its affiliates and/or other parties. No license to or right in any such trademarks, patents, trade secrets, technologies, products, processes and other proprietary rights of PCO, or its affiliates and/or other parties is granted to or conferred upon you.</p>
              <h3>4. DATA PROTECTION AND PRIVACY POLICY</h3>
              <p>Please see our separate Privacy Policy for rules on how we collect and store your data.</p>
              <h3>5. SECURITY</h3>
              <p>We are committed to ensuring that your information is secure. In order to prevent unauthorised access or disclosure, we have put in place suitable physical, electronic and managerial procedures to safeguard and secure the information we collect online.</p>
              <h3>6. COOKIES</h3>
              <p>Please see our separate Privacy Policy for information on cookies.</p>
              <h3>7. LINKS TO OTHER WEBSITES</h3>
              <p>Our Website may contain links to other websites of interest. However, once you have used these links to leave our Site, you should note that we do not have any control over that other website. Therefore, we cannot be responsible for the protection and privacy of any information which you provide whilst visiting such sites and such sites are not governed by this privacy statement. You should exercise caution and look at the privacy statement applicable to the website in question.</p>
              <h3>8. USE OF INFORMATION</h3>
              <p>You may freely browse the Site, but you may only access, download or use information from this Site, including any text, images, audio, and video (the "Information") for your own non-commercial use. You may not distribute, modify, transmit, reuse, repost, or use the Information for commercial purposes, without written permission of PCO.</p>
              <p>You must retain and reproduce each and every copyright notice or other proprietary rights notice contained in any Information you download. You should assume that everything you see or read on this Site is copyrighted unless otherwise noted and may not be used except as provided in these Terms and Conditions or in the text on the Site without the written permission of PCO.</p>
              <h3>9. DISCLAIMER OF WARRANTIES</h3>
              <p>While PCO uses reasonable efforts to ensure that the Information on the website is accurate and up to date, the Information may contain technical inaccuracies or typographical errors. PCO reserves the right to make changes, corrections and/or improvements to the Information, and to the products and programs described in such information, at any time without notice. PCO assumes no liability or responsibility for any errors or omissions in the content of the Site. ALL INFORMATION IS PROVIDED "AS IS." PCO PROVIDES NO WARRANTIES ABOUT THE COMPLETENESS OR ACCURACY OF THE INFORMATION ON THIS SITE OR ITS POSSIBLE USES. CONSEQUENTLY, THE INFORMATION SHOULD BE CAREFULLY EVALUATED BY SITE VISITORS. NEITHER PCO, OR ITS AFFILIATES, NOR ANY OTHER PARTY INVOLVED IN CREATING, PRODUCING OR DELIVERING THIS SITE TO YOU SHALL BE LIABLE FOR ANY DIRECT, INCIDENTAL, CONSEQUENTIAL, INDIRECT OR PUNITIVE DAMAGES ARISING OUT OF ACCESS TO, USE OF OR INABILITY TO USE THIS SITE, OR ANY ERRORS OR OMISSIONS IN THE CONTENT OF THE SITE. Some jurisdictions do not allow the exclusion of implied warranties, so the above exclusion may not apply to you. PCO also assumes no responsibility, and shall not be liable for, any damages to, or viruses that may infect, your computer equipment or other property on account of your access to, use of, the Site and the Information or your downloading of Information. PCO reserves the right to discontinue this Site at any time without notice and without liability.</p>
              <h3>10. INFORMATION YOU SUPPLY TO US</h3>
              <p>Except for information covered by our Data Protection and Privacy Policy, any communication or material you transmit to the Site by electronic mail or otherwise, including any data, questions, comments, suggestions or the like is, and will be treated as, non-confidential and nonproprietary. Anything you transmit or post becomes the property of PCO or its affiliates and may be used for any purpose, including, but not limited to, reproduction, disclosure, transmission, publication, broadcast and posting. Furthermore, PCO is free to use, without compensation to you, any ideas, concepts, know-how, or techniques contained in any communication you send to the Site for any purpose whatsoever including, but not limited to, developing, manufacturing and marketing products using such information.</p>
              <h3>11. LINKS TO THIS SITE</h3>
              <p>PCO has not reviewed any or all of the third-party websites which contain links to this Site and is not responsible for the content of any such off-site pages or any other sites linked to the Site. If you wish to link your website to this Site, you may only link to the home page. You may not link to any other pages within this Site without the prior written consent of PCO. Quotation or use of one or more portions of this Site in the site of any third parties without written consent is likewise prohibited.</p>
              <h3>12. CONSEQUENCES</h3>
              <p>If we become aware that you have violated any of the terms and conditions contained in this Legal Statement, we may immediately take corrective action, including preventing the user from using the services offered by PCO and removing any information, data and content put on the Site by the user, at any moment and without notice. If we have been injured by your violation we may, in our sole discretion, seek to recover damages from you.</p>
              <h3>13. CHANGES TO THE TERMS</h3>
              <p>PCO reserves the right, at its discretion, to make changes to any part of the Website, the Information or these Terms and Conditions.</p>
              <p>You should check this page from time to time to ensure that you are happy with any changes.</p>
              <p>By continuing to use the Website you agree to be bound by these Terms and Conditions as amended.</p>
              <h3>14. GENERAL</h3>
              <p>We cannot guarantee that PCO’s Site will be free from viruses or other harmful material so please update your computer’s virus protection software regularly.</p>
              <p>The provisions contained in each clause and sub-clause of these Terms and Conditions shall be enforceable independently of each of the others and if a provision of these Terms and Conditions of use are, or become illegal, invalid or deemed unenforceable by any court or administrative body of competent jurisdiction it shall not affect the legality, validity or enforceability of any other provisions of these Terms and Conditions.</p>
              <p>These Terms and Conditions are governed by the laws of England and Wales.</p>
            </div>
          </div>
        </Container>
      </main>
    </AppWrapper>
  )
}

export const Container = styled.div`
  background: ${palette.APP_BACKGROUND_COLOR};
  padding: 40px;
  .wrapper{
    padding: 40px 30px;
    max-width: 900px;
    margin: 0px auto;
    background: ${palette.WHITE_COLOR};
    border-radius: 16px;
    z-index: 1;
    position: relative;
    .lastUpdated {
      font-family: ${palette.FONT_FAMILY};
      margin-bottom: 24px;
      font-weight: normal;
      font-size: 1.1rem;
      line-height: 1.5;
      color: #4D5562;
      text-align: center;
    }
    a {
      color: #121826;
      text-decoration: underline;
      font-weight: 600;
      &:hover, &:focus, &:active {
        color: #121826;
        text-decoration: underline;
      }
    }
    b {
      font-weight: bold;
    }
    p, h3 {
      margin: 1em 0;
    }
    p {
      font-weight: normal;
      font-size: 1.2rem;
      line-height: 1.5;
      color: #121826;
    }
    h2 {
      font-family: ${palette.FONT_FAMILY};
      text-align: center;
      font-size: 1.8rem;
      font-weight: 700;
      color: #121826;
    }
    h3 {
      font-size: 1.4rem;
      line-height: 1.5;
      font-weight: 700;
      font-family: ${palette.FONT_FAMILY};
      color: #121826;
      margin-bottom: 0;
    }
    ul {
      list-style: auto;
      padding-left: 30px;
    }
    li {
      font-family: ${palette.FONT_FAMILY};
      font-weight: normal;
      font-size: 1.2rem;
      line-height: 1.5;
      color: #121826;
      padding: 2px 0px;
    }
    i {
      font-style: italic;
    }
    @media (max-width: 768px) {
      padding: 20px;
      margin: 0px;
      .lastUpdated {
        font-size: 16px;
        margin-top: 8px;
      }
      h2 {
        font-size: 24px;
        line-height: 32px;
      }
      h3 {
        font-size: 20px;
      }
      p, li {
        font-size: 16px;
      }
    }
  }
  @media (max-width: 768px) {
    padding: 20px;
  }
`;